@charset "UTF-8";
// Print
@media print {
  #mainSiteProfileInfo,
  #mainSiteMenuMMenuNav,
  .acsBanner,
  .acsHeader,
  .acsFooter,
  #hd,
  #global-banner,
  .addthis_toolbox {
    display: none;
  }
  body {
    font:
      11pt Arial,
      san-serif;
    line-height: 1.3;
  }
  .breadcrumb li {
    display: inline-block;
    font-size: 10pt;
    &:before {
      content: "» ";
    }
    &:first-of-type:before {
      content: "";
    }
  }
}

@page {
  margin: 1cm;
}
